import "@fontsource/work-sans/900.css"
import "@fontsource/work-sans/800.css"
import "@fontsource/work-sans/700.css"
import "@fontsource/work-sans/600.css"
import "@fontsource/work-sans/500.css"
import "@fontsource/work-sans/400.css"
import "@fontsource/work-sans/300.css"
import "@fontsource/work-sans/200.css"
import "@fontsource/work-sans/100.css"
import"@fontsource/roboto/900.css"
import"@fontsource/roboto/700.css"
import"@fontsource/roboto/500.css"
import"@fontsource/roboto/400.css"
import"@fontsource/roboto/300.css"
import"@fontsource/roboto/100.css"

import '@fortawesome/fontawesome-svg-core/styles.css';

import React from 'react';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';

export const wrapRootElement = ({ element }) => (
  <ContentfulLivePreviewProvider locale="en-US">{element}</ContentfulLivePreviewProvider>
);